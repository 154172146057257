import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/offer/:swayOfferId/:addressId?',
    name: 'Offer',
    props: (route) => ({ swayOfferId: route.params.swayOfferId, addressId: route.params.addressId }),
    component: loadView('SwayOffer'),
  },
  {
    path: '/redeemed/:swayOfferProofId',
    name: 'Redeemed',
    props: (route) => ({ swayOfferProofId: route.params.swayOfferProofId }),
    component: loadView('SwayOfferRedeemed'),
  },
  {
    path: '/swayOffersLandingPage/:landingPageId',
    name: 'Sway Offers Landing Page',
    props: (route) => ({ landingPageId: route.params.landingPageId }),
    component: loadView('SwayOffersLandingPage'),
  },
  {
    path: '*',
    name: 'Offers',
    beforeEnter(to, from, next) {
      window.location.href = 'https://getsway.com/'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes],
})

export default router
