<template>
  <div>
    <LfAppEnvironmentBanner v-if="bannerVisible" :environment="environment" :version="appVersion" />

    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'

import { LfAppEnvironmentBanner } from 'lf-common'

export default Vue.extend({
  name: 'App',

  metaInfo: {
    title: 'Sway | spread the word about your fave companies',
  },

  components: {
    LfAppEnvironmentBanner,
  },

  computed: {
    appVersion() {
      return process.env.APP_VERSION
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT
    },
    bannerVisible() {
      return process.env.VUE_APP_ENVIRONMENT === 'Staging'
    },
  },

  mounted() {
    const thirdPartyWidgets = []

    if (process.env.VUE_APP_GOOGLE_TAG_KEY) {
      thirdPartyWidgets.push(
        `window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments)};gtag('js', new Date());gtag('config', '${process.env.VUE_APP_GOOGLE_TAG_KEY}')`
      )

      const gsTagScript = document.createElement('script')
      gsTagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GOOGLE_TAG_KEY}`)
      document.head.appendChild(gsTagScript)
    }

    thirdPartyWidgets.forEach((widget) => {
      const thirdPartyWidget = document.createElement('script')
      thirdPartyWidget.append(widget)
      document.head.appendChild(thirdPartyWidget)
    })
  },
})
</script>
