import axios from 'axios'

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL
    axios.defaults.headers.common['app-type'] = 'Sway'
    axios.defaults.headers.common['app-version'] = process.env.APP_VERSION
  },

  get(resource, config = undefined) {
    return axios.get(resource, config)
  },

  post(resource, data) {
    return axios.post(resource, data)
  },

  put(resource, data) {
    return axios.put(resource, data)
  },

  customRequest() {
    const instance = axios.create()
    instance.defaults.headers.common = {}

    return instance
  },
}

export default ApiService
